<template>
  <v-row class="fill-height py-6 white--text" justify="center">
    <v-col
      cols="12"
      md="10"
      class="d-flex flex-column justify-center my-auto px-16 pt-16"
    >
      <v-form v-model="valid">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <h2 class="display-1 mb-3">Position Details</h2>
            <v-btn
              to="/career-edit"
              @click="position = []"
              color="primary"
              outlined
              rounded
              >Add New Position</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="position.position"
              label="Position"
              hide-details="auto"
              outlined
              dark
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="position.pay"
              label="Pay"
              hide-details="auto"
              type="number"
              outlined
              dark
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="position.location"
              label="Location"
              hide-details="auto"
              outlined
              dark
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="position.startDate"
                  label="Start Date"
                  readonly
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="position.startDate"
                @input="menu = false"
                color="grey darken-2"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="pb-6">
          <v-col cols="12" md="6">
            <v-select
              v-model="position.type"
              label="Position Type"
              hide-details="auto"
              outlined
              :items="types"
              :menu-props="{ bottom: true, offsetY: true }"
              dark
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="position.company"
              label="Company"
              hide-details="auto"
              outlined
              :items="company"
              :menu-props="{ bottom: true, offsetY: true }"
              dark
          /></v-col>
        </v-row>
        <label for="description">Description</label>
        <tiptap-vuetify
          v-model="position.description"
          :extensions="extensions"
          :card-props="{ flat: true, minHeight: '500' }"
        />
        <v-btn
          color="primary"
          :disabled="!valid"
          width="100%"
          class="mt-6"
          outlined
          rounded
          dark
          @click="submit()"
        >
          {{ $route.params.id ? "Update" : "Submit" }}
        </v-btn>
      </v-form>

      <v-snackbar v-model="snackbar">
      <v-icon color="primary">mdi-check</v-icon>
      Success

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

import { positionsCollection } from "../firebase/credentials";
export default {
  components: { TiptapVuetify },
  data() {
    return {
      position: {
        id: "",
        position: "",
        pay: "",
        startDate: "",
        type: "",
        // qualifications: [],
        description: "",
      },
      snackbar: false,
      valid: false,
      menu: false,
      company: ["JLR Contractors", "JLR Woodwork"],
      types: ["Part-Time", "Full-Time", "Contract"],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  methods: {
    async submit() {
      console.log(this.position.id)
      if (this.$route.params.id) {
        await positionsCollection.doc(this.$route.params.id).update(this.position);
      }
      else
        await positionsCollection.add(this.position).then((docRef) => {
          this.position.id = docRef;
          this.$router.push(`/career-edit/${docRef.id}`);
        });
        this.snackbar = true
    },
    async getPosition(id) {
      try {
        const data = await positionsCollection.doc(id).get();
        this.position = data.data();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.position.id = this.$route.params.id;
      this.getPosition(this.position.id);
    }
  },
};
</script>

<style lang="scss" scoped></style>
